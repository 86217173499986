// extracted by mini-css-extract-plugin
export var carouselContainer = "w_qk d_w d_H d_bf d_Z";
export var carouselContainerCards = "w_tp w_qk d_w d_H d_bf d_Z";
export var carouselContainerSides = "w_tq d_w d_H d_Z";
export var prevCarouselItem = "w_tr d_w d_H d_0 d_k";
export var prevCarouselItemL = "w_ts w_tr d_w d_H d_0 d_k";
export var moveInFromLeft = "w_tt";
export var prevCarouselItemR = "w_tv w_tr d_w d_H d_0 d_k";
export var moveInFromRight = "w_tw";
export var selectedCarouselItem = "w_tx d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "w_ty w_tx d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "w_tz w_tx d_w d_H d_Z d_bf";
export var nextCarouselItem = "w_tB d_w d_H d_0 d_k";
export var nextCarouselItemL = "w_tC w_tB d_w d_H d_0 d_k";
export var nextCarouselItemR = "w_tD w_tB d_w d_H d_0 d_k";
export var arrowContainer = "w_tF d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "w_tG w_tF d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "w_tH w_tG w_tF d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "w_tJ w_tF d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "w_tK";
export var nextArrowContainerHidden = "w_tL w_tJ w_tF d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "w_kG d_0";
export var prevArrow = "w_tM w_kG d_0";
export var nextArrow = "w_tN w_kG d_0";
export var carouselIndicatorContainer = "w_tP d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "w_tQ d_w d_bz d_bF";
export var carouselText = "w_tR d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "w_tS w_tR d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "w_tT d_b7";
export var carouselIndicator = "w_tV w_tT d_b7";
export var carouselIndicatorSelected = "w_tW w_tT d_b7";
export var arrowsContainerTopRight = "w_tX d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "w_tY d_0 d_bl d_bC";
export var arrowsContainerSides = "w_tZ d_0 d_bl d_bC";
export var smallArrowsBase = "w_t0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "w_t1 w_t0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "w_t2 w_t1 w_t0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "w_t3 w_t0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "w_t4 w_t3 w_t0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "w_p9";
export var iconSmall = "w_t5";
export var multipleWrapper = "w_t6 d_bC d_bF d_bf";
export var multipleImage = "w_t7 d_bC";
export var sidesPrevContainer = "w_t8 w_t1 w_t0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "w_t9 w_t1 w_t0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";