// extracted by mini-css-extract-plugin
export var lbContainerOuter = "x_vb";
export var lbContainerInner = "x_vc";
export var movingForwards = "x_vd";
export var movingForwardsOther = "x_vf";
export var movingBackwards = "x_vg";
export var movingBackwardsOther = "x_vh";
export var lbImage = "x_vj";
export var lbOtherImage = "x_vk";
export var prevButton = "x_vl";
export var nextButton = "x_vm";
export var closing = "x_vn";
export var appear = "x_vp";