// extracted by mini-css-extract-plugin
export var noMargin = "m_qh d_cc";
export var carouselCol = "m_qj d_cD";
export var carouselWrapper = "m_k6 d_k6 d_bz d_bD d_bP d_w d_H";
export var carouselImageWrapper = "m_k7 d_k7 d_H d_w";
export var carouselContainer = "m_qk d_cD";
export var carouselContainerFull = "m_ql d_dT";
export var carouselImg = "m_mR d_k8 d_w d_H d_bR";
export var imageTextWrapperLeft = "m_k1 d_k1 d_k0 d_0 d_bT";
export var imageTextWrapperCenter = "m_k2 d_k2 d_k0 d_0 d_bT";
export var imageTextWrapperRight = "m_k3 d_k3 d_k0 d_0 d_bT";
export var imageTextWrapperHidden = "m_kZ d_kZ";
export var imageAltSmall = "m_qm d_k4";
export var imageAltNormal = "m_qn d_k4";
export var imageAltLarge = "m_qp d_k4";
export var imageTextSmall = "m_qq d_k5";
export var imageTextNormal = "m_qr d_k5";
export var imageTextLarge = "m_qs d_k5";