// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "s_sp d_bD";
export var storyRowWrapper = "s_hx d_hx d_bK";
export var storyLeftWrapper = "s_sq d_bz d_bP";
export var storyWrapperFull = "s_sr d_cD";
export var storyWrapperFullLeft = "s_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "s_mv d_hy";
export var storyLeftWrapperCenter = "s_ss d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "s_st d_hF";
export var storyHeader = "s_sv d_hD d_w d_cs";
export var storyHeaderCenter = "s_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "s_hB d_hB d_by d_dw";
export var storyBtnWrapper = "s_sw d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "s_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "s_sx d_fg d_Z";
export var imageWrapperFull = "s_sy d_w d_H d_bf d_Z";
export var SubtitleSmall = "s_qd q_qd q_qC q_qP";
export var SubtitleNormal = "s_qf q_qf q_qC q_qQ";
export var SubtitleLarge = "s_qg q_qg q_qC q_qR";
export var textLeft = "s_dv";
export var textCenter = "s_dw";
export var textRight = "s_dx";